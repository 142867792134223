<template lang="pug">
settings-page(title="Информация о компании")
  edit-company-info(v-if="roleAdmin || isTmManager")
  user-company-info(v-else)
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";

import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import EditCompanyInfo from "@/components/pages/settings/companyManagement/EditCompanyInfo.vue";
import UserCompanyInfo from "@/components/pages/admin/extend/UserCompanyInfo.vue";

export default defineComponent({
  name: "ManagementCompany",
  components: {
    SettingsPage,
    UserCompanyInfo,
    EditCompanyInfo,
  },
  setup() {

    const { roleAdmin, isTmManager } = useUserAccess();

    return {
      roleAdmin,
      isTmManager,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
