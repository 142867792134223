<template lang="pug">
settings-panel(v-loading="isFetching")
  template(#header) Компания
  .info-table
    .company-info
      validation-row(
        ref="refLabel"
        not-empty
        label="Название"
        placeholder="Введите название компании"
        v-model="company.title"
        @error="errorChecker('label')"
        @correct="correctChecker('label')"
      )
      form-input(label="Полное название" placeholder="Полное название компании" v-model="company.fullTitle")
    .company-info
      validation-row(
        ref="refInn"
        custom
        hide-asterisk
        v-model="company.inn"
        label="ИНН"
        placeholder="Введите ИНН"
        :custom-checker="customInnChecker"
        @error="errorChecker('inn')"
        @correct="correctChecker('inn')"
      )
      validation-row(
        ref="refKpp"
        custom
        hide-asterisk
        v-model="company.kpp"
        label="КПП"
        placeholder="Введите КПП"
        :custom-checker="customKppChecker"
        @error="errorChecker('kpp')"
        @correct="correctChecker('kpp')"
      )
      validation-row(
        ref="refOgrn"
        custom
        hide-asterisk
        v-model="company.ogrn"
        label="ОГРН"
        placeholder="Введите ОГРН"
        :custom-checker="customOGRNChecker"
        @error="errorChecker('ogrn')"
        @correct="correctChecker('ogrn')"
      )
      form-textarea(v-model="company.legalAddress" label="Юридический адрес" placeholder="Укажите адрес" :max-length="255" :show-word-limit="true")
      form-textarea(v-model="company.factAddress" label="Фактический адрес" placeholder="Укажите адрес" :max-length="255" :show-word-limit="true")
      form-textarea(v-model="company.postAddress" label="Почтовый адрес" placeholder="Укажите адрес" :max-length="255" :show-word-limit="true")
  template(#actions)
    ui-button(:loader="isSaving" @click="validate") Сохранить изменения
    ui-button(v-if="roleAdmin" type="tertiary" @click="openTenderService(undefined, 'signature')") Заказать ЭЦП

pop-confirm(:show-save-button="!errList.length" v-model:show="showPopConfirm" @event="popConfirmEvent")
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, ref } from 'vue';
import { useCompanyManagement } from "@/components/pages/settings/companyManagement/useCompanyManagement";
import { useTenderService } from "@/use/tenderService/useTenderService";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useHasChanges } from "@/use/other/useHasChanges";
import { onBeforeRouteLeave } from "vue-router";
import { useForm } from "~/use/other/useForm";
import { customInnChecker, customKppChecker, customOGRNChecker } from "~/utils/checkers/checkers";

import UiButton from "@/components/ui/button/UiButton.vue";
import FormInput from "@/components/ui/form/input/FormInput.vue";
import FormTextarea from "@/components/ui/form/input/FormTextarea.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import ValidationRow from "~/components/ui/form/input/ValidationRow.vue";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "EditCompanyInfo",
  methods: {
    customOGRNChecker,
    customInnChecker,
    customKppChecker,
  },
  components: {
    ValidationRow,
    UiButton,
    FormInput,
    FormTextarea,
    SettingsPanel,
    PopConfirm: defineAsyncComponent(() => import("@/components/other/PopConfirm.vue")),
  },
  setup() {

    const refInn = ref()
    const refKpp = ref()
    const refOgrn = ref()
    const refLabel = ref()

    const { openTenderService } = useTenderService();
    const { roleAdmin } = useUserAccess();

    const {
      isSaving,
      isFetching,
      editableCompany: company,
      getMyCompany,
      saveMyCompany,
    } = useCompanyManagement();

    const {
      hasChanges,
      showPopConfirm,
      popConfirmEvent,
      checkChanges,
      updateCopy,
    } = useHasChanges(company, onSaveClick);

    getMyCompany()
      .then(() => updateCopy());

    function onSaveClick() {
      saveMyCompany()
        .then(() => updateCopy())
      return true
    }

    onBeforeRouteLeave((to, from, next) => {
      if (to.name !== Tabs.Landing) {
        if (hasChanges.value) {
          validate(false)
          checkChanges(next)
        } else next()
      }
      else next()
    })

    function validate(withSave = true) {
      refLabel.value.validate()
      refInn.value.validate()
      refKpp.value.validate()
      refOgrn.value.validate()
      if (withSave) validateForm()
    }

    const { validateForm, errorChecker, correctChecker, errList } = useForm(onSaveClick);

    return {
      refInn,
      refKpp,
      refOgrn,
      refLabel,
      isSaving,
      isFetching,
      company,
      roleAdmin,
      showPopConfirm,
      errList,
      popConfirmEvent,
      openTenderService,
      validate,
      errorChecker,
      correctChecker,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/companyInfo';
</style>
